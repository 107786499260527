body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* 
body {
  background-color: coral;
} */

.icon :hover {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(20px, 20px) rotate(5deg);
  }
  10% {
    transform: translate(-20px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(5deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(20px, -20px) rotate(1deg);
  }
  50% {
    transform: translate(-20px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 20px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 20px) rotate(-1deg);
  }
  80% {
    transform: translate(-20px, -20px) rotate(1deg);
  }
  90% {
    transform: translate(20px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(20px, -2px) rotate(-1deg);
  }
}
.onoffswitch {
  position: relative;
  width: 51px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.onoffswitch-checkbox {
  display: none;
}
.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;

  border-radius: 13px;
}
.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 25px;
  padding: 0;
  line-height: 25px;
  font-size: 11px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  box-sizing: border-box;
}
.onoffswitch-inner:before {
  content: "Bg";
  padding-left: 7px;
  background-color: #4b4b4b;
  color: #9c9c9c;
}
.onoffswitch-inner:after {
  content: "Cd";
  padding-right: 7px;

  background-color: #4b4b4b;
  color: #9c9c9c;
  text-align: right;
}
.onoffswitch-switch {
  display: block;
  width: 17px;
  margin: 5px;
  background: #9c9c9c;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 25px;
  border: 2px solid #9c9c9c;
  border-radius: 13px;
  transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}
